import React, { useEffect } from "react"
import UIkit from "uikit"
import iosTab from "../../static/pwa/ios_tab.png"

const PWA = () => {
	useEffect(() => {
		if (window !== undefined) {
			const messageContent = `<div class="pwaContent">
					<span>
						Install this webapp on your home screen by Tab&nbsp;&nbsp;
					</span>
					<img src=${iosTab} alt="" />
					<span>
						&nbsp;&nbsp;then 'Add to Home Screen' หรือ 'เพิ่มลงในหน้าจอโฮม'
					</span>
				</div>`

			const isIos = () => {
				const userAgent = window.navigator.userAgent.toLowerCase()
				return /iphone|ipad|ipod/.test(userAgent)
			}
			const isInStandaloneMode = () =>
				"standalone" in window.navigator && window.navigator.standalone
			const isSafari = () => {
				const userAgent = window.navigator.userAgent.toLowerCase()
				return /safari/.test(userAgent)
			}

			const isPWAInstall = localStorage.getItem("isPWAInstall")
			if (isIos() && !isInStandaloneMode() && isSafari() && !isPWAInstall) {
				UIkit.notification({
					message: `<div class="pwaTitle"><span uk-icon='icon: info'></span> Progressive Web Apps</div>${messageContent}`,
					status: "warning",
					pos: "bottom-right",
					timeout: 60000,
				})
				localStorage.setItem("isPWAInstall", "yes")
			}
		}
	})

	return <div />
}

export default PWA
