export const options = {
	mailText: "homkam@matchingwealth.com",
	mailCTA: "mailto:homkam@matchingwealth.com",
	telText: "",
	telCTA: "tel:",
	facebook: "https://www.facebook.com/",
	line: "https://line.me/R/ti/p/",
	youtube: "https://youtube.com/",
}

export default options
