import React, { Fragment } from "react"
/*import UIkit from "uikit"
import Icons from "uikit/dist/js/uikit-icons"*/
import Pwa from "./pwa"

export default function Layout({ children }) {
	/*useEffect(() => {
		if (typeof window !== "undefined") {
			UIkit.use(Icons)
		}
	}, [])*/

	return (
		<Fragment>
			<div className="layoutContainer">{children}</div>
			<Pwa />
		</Fragment>
	)
}
