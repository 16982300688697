import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import {
	GatsbyImage,
	getImage,
	withArtDirection,
	StaticImage,
} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import logoColor from "../../static/logo/logo-color.svg"
import logoWhite from "../../static/logo/logo-white.svg"
import facebook from "../../static/logo/facebook.svg"
import line from "../../static/logo/line.svg"
import email from "../../static/logo/email.svg"
import youtube from "../../static/logo/youtube.svg"
import sticky from "../../static/logo/sticky.svg"
import { options } from "../../config/options"
import "../styles/index.scss"
import { TypeAnimation } from "react-type-animation"
import { AnimationOnScroll } from "react-animation-on-scroll"

import "animate.css/animate.min.css"

const IndexPage = () => {
	const {
		mobileImage1,
		desktopImage1,
		mobileImage2,
		desktopImage2,
		mobileImage3,
		desktopImage3,
		mobileImage4,
		desktopImage4,
		mobileImage5,
		desktopImage5,
		mobileImage7,
		desktopImage7,
		mobileFooter,
		desktopFooter,
	} = useStaticQuery(
		graphql`
			query {
				mobileImage1: file(relativePath: { eq: "mobile-section-banner.jpg" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
				desktopImage1: file(relativePath: { eq: "banner-homkam-web.jpg" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
				mobileImage2: file(relativePath: { eq: "section-mb-2-min.png" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
				desktopImage2: file(relativePath: { eq: "section2-bg.png" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
				mobileImage3: file(relativePath: { eq: "section-mb-3-min.png" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
				desktopImage3: file(relativePath: { eq: "section3-bg.png" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
				mobileImage4: file(relativePath: { eq: "section-mb-4-min.png" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
				desktopImage4: file(relativePath: { eq: "section4-bg.jpg" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
				mobileImage5: file(relativePath: { eq: "section-mb-5-min.png" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
				desktopImage5: file(relativePath: { eq: "section5-bg.jpg" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
				mobileImage7: file(relativePath: { eq: "section-mb-7-min.png" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
				desktopImage7: file(relativePath: { eq: "section7-bg.jpg" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
				mobileFooter: file(relativePath: { eq: "footer-mb-min.png" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
				desktopFooter: file(relativePath: { eq: "footer-bg.jpg" }) {
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		`
	)
	const sources1 = withArtDirection(getImage(desktopImage1), [
		{
			media: "(max-width: 959px)",
			image: getImage(mobileImage1),
		},
	])
	const sources2 = withArtDirection(getImage(desktopImage2), [
		{
			media: "(max-width: 959px)",
			image: getImage(mobileImage2),
		},
	])
	const sources3 = withArtDirection(getImage(desktopImage3), [
		{
			media: "(max-width: 959px)",
			image: getImage(mobileImage3),
		},
	])
	const sources4 = withArtDirection(getImage(desktopImage4), [
		{
			media: "(max-width: 959px)",
			image: getImage(mobileImage4),
		},
	])
	const sources5 = withArtDirection(getImage(desktopImage5), [
		{
			media: "(max-width: 959px)",
			image: getImage(mobileImage5),
		},
	])
	const sources7 = withArtDirection(getImage(desktopImage7), [
		{
			media: "(max-width: 959px)",
			image: getImage(mobileImage7),
		},
	])
	const sourceFooter = withArtDirection(getImage(desktopFooter), [
		{
			media: "(max-width: 959px)",
			image: getImage(mobileFooter),
		},
	])

	return (
		<Layout>
			<section className="sectionBackground section1" style={{ zIndex: 4 }}>
				<GatsbyImage image={sources1} alt="" layout="fullWidth" />
				<div className="logoWrapper">
					<div className="uk-container" style={{ textAlign: "center" }}>
						<img src={logoColor} width="71" height="95" alt="HOMKAM" />
					</div>
				</div>
				<div className="contentWrapper">
					<div className="uk-container">
						<div className="uk-grid" data-uk-grid>
							<div className="uk-width-1-2@s uk-width-1-2@l"></div>
							<div className="uk-width-1-2@s uk-width-1-2@l">
								<div className="heroContent">
									<h1 className="heroTitle">
										<span className="top">
											<AnimationOnScroll animateIn="animate__fadeInRight">
												PREMIUM
											</AnimationOnScroll>
										</span>
										<span className="bottom">
											<AnimationOnScroll animateIn="animate__fadeIn">
												<TypeAnimation
													sequence={["JASMINE RICE 100%", 3000, ""]}
													speed={60}
													repeat={Infinity}
												/>
											</AnimationOnScroll>
										</span>
									</h1>
									<p className="heroDesc">
										<AnimationOnScroll animateIn="animate__fadeIn animate__delay-1s">
											HOMKAM Jasmine Rice 100% No mixed Delicious, Flavorful,
											Soft; even if it is cool.
										</AnimationOnScroll>
									</p>
									<AnimationOnScroll animateIn="animate__fadeIn animate__delay-2s">
										<a
											href={options.mailCTA}
											className="button"
											target="_blank"
											rel="noreferrer"
										>
											Contact
										</a>
									</AnimationOnScroll>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="sectionBackground section2" style={{ zIndex: 3 }}>
				<GatsbyImage image={sources2} alt="" layout="fullWidth" />
				<div className="contentWrapper">
					<div className="uk-container">
						<div className="uk-grid" data-uk-grid>
							<div className="uk-width-1-2@s uk-width-1-2@l">
								<AnimationOnScroll animateIn="animate__bounceIn">
									<span className="sectionSub">
										JASMINE RICE 100% Mid-year Rice
									</span>
								</AnimationOnScroll>
								<h2 className="sectionTitle">
									<AnimationOnScroll animateIn="animate__fadeIn">
										HOMKAM Jasmine Rice 100% No mixed Delicious, Flavorful,
										Soft; even if it is cool.
									</AnimationOnScroll>
								</h2>
								<p className="sectionDesc">
									<AnimationOnScroll animateIn="animate__fadeIn">
										HOMKAM is a premium rice brand that carefully grows all rice
										grains to deliver the best, high-quality rice to all
										worldwide kitchens. HOMKAM understood and believed that
										eating high-quality Jasmine rice with all meals would make
										you more delicious with all meals. HOMKAM punctiliously
										selected the Jasmine rice breed for valuable households,
										exporting and serving to global kitchens.
									</AnimationOnScroll>
								</p>
							</div>
							<div className="uk-width-1-2@s uk-width-1-2@l"></div>
						</div>
					</div>
				</div>
			</section>
			<section className="sectionBackground section3" style={{ zIndex: 2 }}>
				<GatsbyImage image={sources3} alt="" layout="fullWidth" />
				<div className="contentWrapper">
					<div className="uk-container">
						<div className="uk-grid" data-uk-grid>
							<div className="uk-width-1-2@s uk-width-1-2@l"></div>
							<div className="uk-width-1-2@s uk-width-1-2@l">
								<AnimationOnScroll animateIn="animate__bounceIn">
									<span className="sectionSub">
										PREMIUM THAI GLUTINOUS RICE
									</span>
								</AnimationOnScroll>
								<h2 className="sectionTitle">
									<AnimationOnScroll animateIn="animate__fadeIn">
										HOMKAM- RD 6; King of Thai Glutinous rice: Dulcet,
										Delicious-Duo Menu.
									</AnimationOnScroll>
								</h2>
								<p className="sectionDesc">
									<AnimationOnScroll animateIn="animate__fadeIn">
										HOMKAM is a quality exporting Thai rice brand; we have
										selected the best glutinous rice varieties. HOMKAM has
										thoroughly studied and analyzed that glutinous rice (or
										“sticky rice”) is one of the most popular worldwide. Mainly,
										Thai restaurants in European countries must serve “Sticky
										rice” on their menu. Moreover, it can be processed into
										glutinous rice flour. It is also used in the industry for
										processing into snacks. With all benefits of glutinous rice
										as above-mentioned, the HOMKAM brand selected RD 6 glutinous
										rice varieties for all families could experience tasty of
										the best ingredients.
									</AnimationOnScroll>
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="sectionBackground section4" style={{ zIndex: 1 }}>
				<GatsbyImage image={sources4} alt="" layout="fullWidth" />
				<div className="contentWrapper">
					<div className="uk-container" style={{ width: "100%" }}>
						<h2 className="productTitle">
							<AnimationOnScroll animateIn="animate__bounceIn">
								Our Products
							</AnimationOnScroll>
						</h2>
						<div
							className="uk-slider-container-offset"
							data-uk-slider="autoplay: true"
						>
							<div
								className="uk-position-relative uk-visible-toggle uk-light"
								tabIndex="-1"
							>
								<ul className="uk-slider-items uk-child-width-1-2@s uk-child-width-1-3@m uk-grid">
									<li>
										<div className="item">
											<StaticImage
												src="../images/1-homkam-premium-thai-jasmine-20kg.png"
												alt=""
												layout="constrained"
											/>
											<p className="text">
												PREMIUM THAI JASMINE RICE 100%
												<br />
												(20KGS)
											</p>
										</div>
									</li>
									<li>
										<div className="item">
											<StaticImage
												src="../images/2-homkam-thai-jasmine-20kg.png"
												alt=""
												layout="constrained"
											/>
											<p className="text">
												THAI JASMINE RICE 100%
												<br />
												(20KGS)
											</p>
										</div>
									</li>
									<li>
										<div className="item">
											<StaticImage
												src="../images/3-homkam-premium-thai-jasmin-rice-10km.png"
												alt=""
												layout="constrained"
											/>
											<p className="text">
												PREMIUM THAI JASMINE RICE 100%
												<br />
												(10KGS)
											</p>
										</div>
									</li>
									<li>
										<div className="item">
											<StaticImage
												src="../images/4-homkam-thai-jasmine-rice-10kg.png"
												alt=""
												layout="constrained"
											/>
											<p className="text">
												THAI JASMINE RICE 100%
												<br />
												(10KGS)
											</p>
										</div>
									</li>
									<li>
										<div className="item">
											<StaticImage
												src="../images/5-homkam-glutinous-rice-5kg.png"
												alt=""
												layout="constrained"
											/>
											<p className="text">
												THAI GLUTINOUS RICE 100%
												<br />
												(5KGS)
											</p>
										</div>
									</li>
								</ul>
							</div>
							<ul className="uk-slider-nav uk-dotnav uk-flex-center"></ul>
						</div>
					</div>
				</div>
			</section>
			<section className="sectionBackground section5" style={{ zIndex: 2 }}>
				<GatsbyImage image={sources5} alt="" layout="fullWidth" />
			</section>
			<section
				className="sectionBackground section6"
				style={{ zIndex: 1, marginTop: "-20px" }}
			>
				<div className="contentWrapper">
					<div className="uk-container">
						<h2 className="sectionTitle2" style={{ textAlign: "center" }}>
							<AnimationOnScroll animateIn="animate__bounceIn">
								HOMKAM Jasmine Rice <br className="hiddenSmallMobile" />
								Delicious and Tasty
							</AnimationOnScroll>
						</h2>
						<p
							className="sectionDesc"
							style={{ textAlign: "center", color: "#fff" }}
						>
							<AnimationOnScroll animateIn="animate__fadeIn">
								HOMKAM has selected “Khao Dok Mali 105,” which is high-quality
								planted by annual rice cultivation (in-season rice) on highlands
								in Northeast Thailand (Southern Isaan). This land is famous and
								well-known as arid, with sandy soil mixed with a bit of
								salinity. However, because of the drought of earth, air, and
								weather conditions, rice plants generated a type of rubber to
								cover the grain; the Jasmine rice can grow well and become the
								best Jasmine rice in Thailand. The Jasmine rice of this land is
								distinctive as follows;
							</AnimationOnScroll>
						</p>
					</div>
				</div>
				<div className="contentWrapper">
					<div className="uk-container hero2">
						<div className="uk-grid uk-grid-large uk-grid-divider" data-uk-grid>
							<div className="uk-width-1-2@m">
								<div className="box left">
									<span className="sectionSub">
										<AnimationOnScroll animateIn="animate__fadeInLeft">
											Sweet-smelling
										</AnimationOnScroll>
									</span>
									<p className="sectionDesc">
										<AnimationOnScroll animateIn="animate__fadeInLeft">
											with a humid climate alternate with drought, the field is
											getting dry without waterlogging. During the harvesting
											season, around November – December starts winter, cold
											weather is a good factor that makes Khao Dok Mali 105
											aromatic. Especially when the rice is cooked, it will
											exude a rich aroma. Sweet-smelling became the unique Khao
											Dok Mali 105.
										</AnimationOnScroll>
									</p>
								</div>
								<div className="box left">
									<span className="sectionSub">
										<AnimationOnScroll animateIn="animate__fadeInLeft">
											Soft experience
										</AnimationOnScroll>
									</span>
									<p className="sectionDesc">
										<AnimationOnScroll animateIn="animate__fadeInLeft">
											Cooked rice is sweet-smelling, smooth, and not too dry or
											too wet. It is proper for everyone in families, and
											especially the elderly can easily chew and digest it; it
											does not irritate the stomach.
										</AnimationOnScroll>
									</p>
								</div>
								<div className="box left">
									<span className="sectionSub">
										<AnimationOnScroll animateIn="animate__fadeInLeft">
											It no hard when it is cool
										</AnimationOnScroll>
									</span>
									<p className="sectionDesc">
										<AnimationOnScroll animateIn="animate__fadeInLeft">
											the Special qualify of Khao Dok Mali 105 grains are not
											hard even if it is cool after cooking. Its taste is still
											soft and flavorful.
										</AnimationOnScroll>
									</p>
								</div>
							</div>
							<div className="uk-width-1-2@m">
								<h2 className="sectionTitle2">
									<AnimationOnScroll animateIn="animate__fadeInRight">
										HOMKAM Jasmine Rice Thai rice for all menus.
									</AnimationOnScroll>
								</h2>
								<div className="box right">
									<p className="sectionDesc">
										<AnimationOnScroll animateIn="animate__fadeInRight">
											HOMKAM carefully selected rice grains frฟหๆom the best
											Kkao Dok Mali 105 breed for delivery to worldwide
											kitchens. All HOMKAM rice is perfect, full oฟหf grains,
											unbroken, and with no additives. The Rice color is white
											like Jasmine. When you open the rice bag, you will smell
											sweet-scented. In addition, you can give HOMKAM rice as
											presents, souvenirs for people who love and care.
										</AnimationOnScroll>
									</p>
								</div>
								<div className="box right">
									<p className="sectionDesc">
										<AnimationOnScroll animateIn="animate__fadeInRight">
											HOMKAM has carefully researched and sought rice breeds
											from high-quality sources for Thai and global people. The
											essential factor that HOMKAM has selected for Khao Dok
											Mali 105 is intentionally choosing 199 bunches of pure
											breeds in B.E.2493 – 2494. All rice grains were planted
											and compared with other rice in different localities until
											they got good grade white Jasmine rice in 105th bunches.
											Finally, it was certified by breeding committees on 25 May
											B.E. 2502.
										</AnimationOnScroll>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="sectionColor">
				<div className="uk-container">
					<h2 className="text">
						<AnimationOnScroll animateIn="animate__bounceIn">
							FOR SOME Rice PREMIUM <br className="hiddenSmallMobile" />
							GET IN TOUCH
						</AnimationOnScroll>
					</h2>
				</div>
			</section>
			<section className="sectionBackground section7" style={{ zIndex: 2 }}>
				<GatsbyImage image={sources7} alt="" layout="fullWidth" />
				<div className="contentWrapper">
					<div className="uk-container">
						<div className="displayImage">
							<AnimationOnScroll animateIn="animate__bounceIn">
								<StaticImage
									src="../images/product-hero.png"
									alt=""
									layout="constrained"
								/>
							</AnimationOnScroll>
						</div>
						<h2 className="sectionTitle2" style={{ textAlign: "center" }}>
							<AnimationOnScroll animateIn="animate__bounceIn">
								RD 6 is the best glutinous rice{" "}
								<br className="hiddenSmallMobile" />
								selected for all households by HOMKAM.
							</AnimationOnScroll>
						</h2>
					</div>
				</div>
			</section>
			<section
				className="sectionBackground section8"
				style={{ zIndex: 1, marginTop: "-20px" }}
			>
				<div className="contentWrapper">
					<div className="uk-container">
						<div className="uk-grid uk-grid-large uk-grid-divider" data-uk-grid>
							<div className="uk-width-1-2@m">
								<div className="box left">
									<span className="sectionSub">
										<AnimationOnScroll animateIn="animate__fadeInLeft">
											Originating from an excellent breed;
										</AnimationOnScroll>
									</span>
									<p className="sectionDesc">
										<AnimationOnScroll animateIn="animate__fadeInLeft">
											White Jasmine rice (Khao Dok Mali 105) is the best and
											most unique Jasmine rice; it is an origin breed for RD6
											glutinous rice development. Office of Atoms for Peace of
											Thailand improved rice breed by gamma irradiation at 20
											Krad in B.E. 2508. After that, they were planted at the
											Phimai Rice Experiment Station. From experimentation,
											“RD6” glutinous rice originated from an excellent model.
											Therefore, HOMKAM has selected “RD6” to represent Thai
											glutinous rice for exporting abroad.
										</AnimationOnScroll>
									</p>
								</div>
								<div className="box left">
									<span className="sectionSub">
										<AnimationOnScroll animateIn="animate__fadeInLeft">
											Improving the outstanding nature of the superb Jasmine
											rice
										</AnimationOnScroll>
									</span>
									<p className="sectionDesc">
										<AnimationOnScroll animateIn="animate__fadeInLeft">
											RD6 has been bred from Thai White Jasmine rice (Khao Dok
											Mali 105), the best soft-cooking rice. RD6 glutinous rice
											is softer than other rice and sweet-smelling when cooked.
											Even if it is cool after cooking, it is not hard and
											crumbly. In the cultivation process, it can tolerate
											drought well and high production.
										</AnimationOnScroll>
									</p>
								</div>
								<div className="box left">
									<span className="sectionSub">
										<AnimationOnScroll animateIn="animate__fadeInLeft">
											Best quality cultivation sources
										</AnimationOnScroll>
									</span>
									<p className="sectionDesc">
										<AnimationOnScroll animateIn="animate__fadeInLeft">
											RD 6 glutinous rice by HOMKAM is grown on highlands in
											Northeast Thailand (Southern Isaan) in the same
											agriculture area of Khao Dok Mali 105, the best Thai
											Jasmine rice. The HOMKAM glutinous rice is planted by
											annual rice cultivation (in-season rice) so that HOMKAM
											products are perfect and not easily broken. The cooked
											rice is flavorful and tasty.
										</AnimationOnScroll>
									</p>
								</div>
							</div>
							<div className="uk-width-1-2@m">
								<h2 className="sectionTitle2">
									<AnimationOnScroll animateIn="animate__fadeInRight">
										The HOMKAM glutinous rice is valuable for health & heart.
									</AnimationOnScroll>
								</h2>
								<div className="box right">
									<p className="sectionDesc">
										<AnimationOnScroll animateIn="animate__fadeInRight">
											The glutinous rice benefits health plentifully. A lot of
											high vitamin E in rice qualifies to help functional
											nervous and brain systems. It prevents vitreous
											deterioration and reduces the risk of coronary heart
											disease. In addition, glutinous rice is classified as a
											portion of food with “warm” energy; it can maintain the
											body’s temperature to withstand cold weather. Therefore,
											HOMKAM glutinous rice is proper for all countries around
											the world.
										</AnimationOnScroll>
									</p>
								</div>
								<div className="box right">
									<p className="sectionDesc">
										<AnimationOnScroll animateIn="animate__fadeInRight">
											RD6 glutinous rice by HOMKAM is the best breed that is
											valuable and suitable for exporting worldwide and lets
											people experience the unique Thai rice flavor.
											Delicious-Duo menu, we can eat cooked sticky rice with
											several menus. Raw rice can be used as an ingredient for
											making various dessert menus. RD6 is tasty and flavorful;
											you can feel soft and delicious with every bite of Food.
											The HOMKAM understood and believed that Food is connective
											to people worldwide without borders.
										</AnimationOnScroll>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="sectionBackground footer" style={{ zIndex: 1 }}>
				<GatsbyImage image={sourceFooter} alt="" layout="fullWidth" />
				<div className="contentWrapper">
					<div className="uk-container">
						<div className="flexWrapper">
							<div className="top">
								<h2 className="text">
									<AnimationOnScroll animateIn="animate__bounceIn">
										FOR SOME Rice PREMIUM <br className="hiddenSmallMobile" />
										GET IN TOUCH
									</AnimationOnScroll>
								</h2>
							</div>
							<div className="bottom">
								<div>
									<div className="logoWrapper">
										<AnimationOnScroll animateIn="animate__fadeIn">
											<img
												src={logoWhite}
												width="103"
												height="139"
												alt="HOMKAM"
											/>
										</AnimationOnScroll>
									</div>
									<ul className="social">
										<li>
											<a
												href="#facebook"
												target="_blank"
												rel="noreferrer"
												onClick={(e) => e.preventDefault()}
											>
												<img
													src={facebook}
													width="40"
													height="40"
													alt="Facebook"
												/>
											</a>
										</li>
										<li>
											<a
												href="#line"
												target="_blank"
												rel="noreferrer"
												onClick={(e) => e.preventDefault()}
											>
												<img src={line} width="40" height="40" alt="Line" />
											</a>
										</li>
										<li>
											<a
												href={options.mailCTA}
												target="_blank"
												rel="noreferrer"
											>
												<img src={email} width="40" height="40" alt="Email" />
											</a>
										</li>
										<li>
											<a
												href="#youtube"
												target="_blank"
												rel="noreferrer"
												onClick={(e) => e.preventDefault()}
											>
												<img
													src={youtube}
													width="40"
													height="40"
													alt="Youtube"
												/>
											</a>
										</li>
									</ul>
									<p className="copyright">
										Matching Wealth Co.,Ltd. 99/194 Soi Krungthep Kreetha 39,
										Saphan Sung Subdistrict, Saphan Sung District, Bangkok 10250 Thailand
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<div className="uk-inline toggleContact">
				<button className="uk-button uk-button-default" type="button">
					<img src={sticky} width="52.31" height="60.4" alt="Contact list" />
					<span>Contact</span>
				</button>
				<div
					className="uk-card uk-card-body uk-card-default"
					data-uk-drop="animation: slide-right; animate-out: true; duration: 700"
				>
					<ul className="social">
						<li>
							<a href={options.mailCTA} target="_blank" rel="noreferrer">
								<img src={email} width="28" height="28" alt="Email" />
								<span>{options.mailText}</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</Layout>
	)
}

export default IndexPage

export const Head = () => (
	<Seo
		title={`HOMKAM PREMIUM JASMINE RICE 100%`}
		description={`HOMKAM is a premium rice brand that carefully grows all rice grains to deliver the best, high-quality rice to all worldwide kitchens.`}
	/>
)
