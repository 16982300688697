import React from "react"
import { StaticQuery, graphql } from "gatsby"

const Seo = ({
	lang,
	title,
	description,
	banner,
	pathname,
	article,
	author,
}) => (
	<StaticQuery
		query={query}
		render={({
			site: {
				buildTime,
				siteMetadata: {
					siteUrl,
					defaultTitle,
					defaultDescription,
					defaultBanner,
					titleAlt,
					shortName,
					defaultAuthor,
					siteLanguage,
					pathPrefix,
					logo,
				},
			},
		}) => {
			const seo = {
				lang: lang || siteLanguage,
				title: title || defaultTitle,
				description: description || defaultDescription,
				image: banner ? `https:${banner}` : `${defaultBanner}`,
				url: pathname || siteUrl,
				author: author || defaultAuthor,
			}
			const realPrefix = pathPrefix === "/" ? "" : pathPrefix
			let schemaOrgJSONLD = [
				{
					"@context": "http://schema.org",
					"@type": "WebSite",
					"@id": siteUrl,
					url: siteUrl,
					name: defaultTitle,
					alternateName: titleAlt || "",
				},
			]
			if (article) {
				schemaOrgJSONLD = [
					{
						"@context": "http://schema.org",
						"@type": "BlogPosting",
						"@id": siteUrl,
						url: seo.url,
						name: title,
						alternateName: titleAlt || "",
						headline: title,
						image: {
							"@type": "ImageObject",
							url: seo.image,
						},
						description: seo.description,
						datePublished: buildTime,
						dateModified: buildTime,
						author: {
							"@type": "Person",
							name: seo.author,
						},
						publisher: {
							"@type": "Organization",
							name: seo.author,
							logo: {
								"@type": "ImageObject",
								url: siteUrl + realPrefix + logo,
							},
						},
						isPartOf: siteUrl,
						mainEntityOfPage: {
							"@type": "WebSite",
							"@id": siteUrl,
						},
					},
				]
			}
			return (
				<>
					<title>{seo.title}</title>
					<meta name="description" content={seo.description} />
					<meta name="image" content={seo.image} />
					<meta name="apple-mobile-web-app-title" content={shortName} />
					<meta name="application-name" content={shortName} />
					<script type="application/ld+json">
						{JSON.stringify(schemaOrgJSONLD)}
					</script>

					<meta property="og:url" content={seo.url} />
					<meta property="og:type" content={article ? "article" : null} />
					<meta property="og:title" content={seo.title} />
					<meta property="og:description" content={seo.description} />
					<meta property="og:image" content={seo.image} />
				</>
			)
		}}
	/>
)

export default Seo

const query = graphql`
	query SEO {
		site {
			buildTime(formatString: "YYYY-MM-DD")
			siteMetadata {
				siteUrl
				defaultTitle
				defaultDescription
				defaultBanner
				titleAlt
				shortName
				defaultAuthor
				siteLanguage
				pathPrefix
				logo
			}
		}
	}
`
